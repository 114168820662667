import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(7),
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    marginBottom: theme.spacing(4.5),
  },
}));

export const Text = styled('div')(({ theme }) => ({
  p: {
    ...theme.typography.body1,
    marginTop: 0,
  },
  'p:last-child': {
    marginBottom: 0,
  },
}));

export const Header = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('lg')]: {
    marginBottom: theme.spacing(3.5),
  },
}));
