import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import PromisesIcon1 from '@/assets/promise1.svg';
import PromisesIcon2 from '@/assets/promise2.svg';
import PromisesIcon3 from '@/assets/promise3.svg';
import Paper from '@/components/ui/Paper/Paper';
import * as i18n from '@/helpers/i18n';

import { Header, Text, Wrapper } from './Promises.style';

const data = [
  {
    image: <PromisesIcon1 width="60" height="60" />,
    titleId: 'promises_sub1',
    descriptionId: 'promises_text1',
  },
  {
    image: <PromisesIcon2 width="60" height="60" />,
    titleId: 'promises_sub2',
    descriptionId: 'promises_text2',
  },
  {
    image: <PromisesIcon3 width="60" height="60" />,
    titleId: 'promises_sub3',
    descriptionId: 'promises_text3',
  },
];

/**
 * Promises section.
 */
const Promises = () => (
  <Wrapper>
    <Header variant="h2">
      <FormattedMessage id="promises_head" />
    </Header>
    <Paper>
      <Stack
        px={{ xs: 4.5, md: 5.5 }}
        py={4}
        direction={{ xs: 'column', lg: 'row' }}
        justifyContent="space-between"
        spacing={4.5}>
        {data.map(({ image, descriptionId, titleId }) => (
          <Box flexBasis="33%" key={titleId}>
            <Stack
              direction="row"
              height={theme => theme.typography.pxToRem(70)}
              mb={2.5}
              alignItems="center">
              <Box mr={1.5} maxHeight={60} maxWidth={60}>
                {image}
              </Box>
              <Typography variant="h4" component="div">
                <FormattedMessage id={titleId} />
              </Typography>
            </Stack>
            <Text>
              <FormattedMessage
                id={descriptionId}
                values={{
                  ...i18n.elements,
                }}
              />
            </Text>
          </Box>
        ))}
      </Stack>
    </Paper>
  </Wrapper>
);

Promises.propTypes = {
  /** API access to translations */
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Promises);
